.ContentMemberTransfer {
    padding: 10px 20px;
}

.comntent-MemberTransfer {
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 14px;
    /* height: 87vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-bottom: 4rem !important;
}

.ContentMemberTransfer .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.ContentMemberTransfer .main-subtitle {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.ContentMemberTransfer .form-check-input {
    padding: 8px;
}

.ContentMemberTransfer .form-check-input:checked {
    background-color: #03335b !important;
    border-color: #03335b !important;
}

.input-field-ui-table {
    border: 1px solid #DCDCDC;
    padding: 4px 30px 4px 10px;
    border-radius: 12px;
    width: 100%;
}

.btn-Edit {
    color: #03335b !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    padding: 0 !important;
}

.btn-Edit:hover {
    color: #1279be !important;
    font-weight: 600 !important;
}

.btn-Cancel {
    color: #FF0000 !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    padding: 0 !important;
}

.btn-Cancel:hover {
    color: #1279be !important;
    font-weight: 600 !important;
}

.MemberTransferTable .gdBOih:nth-child(1),
.MemberTransferTable .dBiUCQ:nth-child(1) {
    min-width: 100px !important;
    max-width: 100px !important;
}

.MemberTransferTable .gdBOih:nth-child(2),
.MemberTransferTable .dBiUCQ:nth-child(2) {
    min-width: 140px !important;
    max-width: 140px !important;
}

.MemberTransferTable .gdBOih:nth-child(3),
.MemberTransferTable .dBiUCQ:nth-child(3) {
    min-width: 160px !important;
    max-width: 160px !important;
}

.MemberTransferTable .gdBOih:nth-child(4),
.MemberTransferTable .dBiUCQ:nth-child(4) {
    min-width: 180px !important;
    max-width: 180px !important;
}

.MemberTransferTable .gdBOih:nth-child(5),
.MemberTransferTable .dBiUCQ:nth-child(5) {
    min-width: 180px !important;
    max-width: 180px !important;
}

.MemberTransferTable .gdBOih:nth-child(6),
.MemberTransferTable .dBiUCQ:nth-child(6) {
    min-width: 300px !important;
}

.MemberTransferTable .gdBOih:nth-child(7),
.MemberTransferTable .dBiUCQ:nth-child(7) {
    min-width: 190px !important;
}

.MemberTransferTable .gdBOih:nth-child(8),
.MemberTransferTable .dBiUCQ:nth-child(8) {
    min-width: 210px !important;
}