

.ContentRPMMembeProfile{
  padding: 10px 20px;
}
.RPMMembeProfile-content-wrapper {
    align-items: flex-start;
    display: flex
;
    justify-content: flex-start;
    width: 100%;
}
.member-RPMMembeProfile{
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 14px;
  /* height: 87vh; */
  display: block;
  width: 100%;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  /* margin-bottom: 4rem !important; */
}

.member-RPMMembeProfile::-webkit-scrollbar{
  display: none;
}

.ContentRPMMembeProfile .main-title {
  font-size: 22px;
  color: #656565;
  font-weight: 600;
  margin-bottom: 10px;
}

.RPMMembeProfile-content-wrapper-inner {
    height: 100%;
    width: 76%;
    margin-right: 1%;
}

.RPMMembeProfile-content-aside {
    position: sticky;
    /* top: 15%; */
    width: 23%;
    background-color: #fff;
    color: #000;
    /* padding: 10px; */
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.content-aside-inner-rpm{
    /* display: flex; */
    /* padding: 10px; */
    /* flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch; */
    background: #0079C8;
    border-radius: 4px;
}

.content-aside-inner-rpm-header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.content-aside-inner-rpm-header h1{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.rpm-profile-images{
    background-color: #fff;
    border: 1px solid #e8edf7;
    border-radius: 242px;
    display: block;
    width: 94px;
    height: 94px;
    object-fit: cover;
}

.content-aside-inner-rpm-body{
    border-radius: 0%;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: var(--dark-blue-drak-blue-100, #00398A);
}
.content-aside-inner-rpm-body-item{
    width: 50%;
    border-right: 1px solid  rgba(255, 255, 255, 0.15);
}

.content-aside-inner-rpm-body-item:last-child{
    border-radius: 4px;
    border-right: 0px solid  rgba(255, 255, 255, 0.15);
}
/* 
.content-aside-inner-rpm-body-item:nth-child(2){
    border-radius: 4px;
    border-left: 1px solid  rgba(255, 255, 255, 0.15);
    border-right: 1px solid  rgba(255, 255, 255, 0.15);
} */

.content-aside-inner-rpm-body-item h2{
    color: var(--white-white-100, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    text-align: center;
}

.content-aside-inner-rpm-body-item h3{
    color: var(--white-white-100, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
}

.content-aside-inner-rpm-tille h4{
    color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 20px 10px 0px 10px;
}
.content-aside-inner-rpm-footer{
    padding: 0px 10px 10px 10px;
    border-radius: 4px;
    overflow-y: scroll;
    height: 49vh;
    margin-bottom: 14px;
    overflow-x: hidden;
}

.content-aside-inner-rpm-footer-item{
    width: 100%;
    border-bottom: 1px solid #EEF5FB;
    padding: 10px 0;
    display: flex;
    /* align-items: center; */
}
.content-aside-inner-rpm-footer-item:last-child{
    border-bottom: none;
}

.content-aside-inner-rpm-footer-item h2{
    color: #333;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.1rem;
}

.content-aside-inner-rpm-footer-item h3{
    color: #636773;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0.3rem;
}

.content-aside-inner-rpm-footer-item p{
    color: #636773;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0rem;
}

.content-aside-inner-rpm-footer-item-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: rgba(113, 221, 177, 0.11);
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    fill: #0079C8;
    color: #0079C8;
}       

.content-aside-inner-rpm-footer-item-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
     margin-left: 14px;
}


.content-aside-inner-rpm-footer-item-text p span{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #EC8938;
    margin-left: 0.5rem;
}  

.card-consultation-text{
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
    text-align: center;
}

.rpm-dashboard-btn {
    color: #fff !important;
    background: transparent !important;
    border: 1px solid #fff !important;
    font-size: 16px !important;
    padding: 4px 8px !important;
    border-radius: 8px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
}

.rpm-dashboard-btn:hover {
    background: #fff !important;
    color: #0079C8 !important;
}
